<style scoped>
	div {
		box-sizing: border-box;
	}
	img {
		object-fit: cover;
	}
	/*  */
	.content {
		width: 1320px;
		margin: 0 auto;
	}

	.top-title {
		margin-top: 130px;
		padding: 0 30px;
		text-align: center;
	}

	.title-text1 {
		font-size: 31px;
		font-weight: bold;
		color: #222;
	}

	.title-text2 {
		color: #666;
		font-size: 15px;
		font-weight: bold;
		margin: 60px 0 85px;
	}

	.content2 {
		width: 1320px;
		margin: 0 auto 82px;
	}
	.con2-item:hover {
		background-image: url(../../../assets/images/index-nav3-bg_03.jpg);
		background-size: 100% 100%;
	}
	.con2-item:hover .con2-p1 {
		color: #fff;
	}
	.con2-item:hover .con2-p2 {
		color: #fff;
	}
	.con2-item:hover .con2-div {
		width: 163px;
		background: #fff;
	}
	.con2-item:hover .con2-img1 {
		content: url(../../../assets/images/product-img12-2.png);
	}
	.con2-item:hover .con2-img2 {
		content: url(../../../assets/images/product-img13-2.png);
	}
	.con2-item:hover .con2-img3 {
		content: url(../../../assets/images/product-img14-2.png);
	}
	.con2-item:hover .con2-img4 {
		content: url(../../../assets/images/product-img15-2.png);
	}
	.con2-item:hover .con2-img5 {
		content: url(../../../assets/images/product-img16-2.png);
	}
	.content2 > div {
		width: calc((100% - 304px) / 5);
		margin-right: 76px;
		height: 244px;
		border: 1px solid #E6E9F0;
		box-shadow: 0 0 30px #F8F8F9;
		border-radius: 10px;
		cursor: pointer;
		flex-direction: column;
		padding: 10px 20px;
	}
	.content2 > div:last-child {
		margin-right: 0;
	}
	.con2-item > img {
		width: 88px;
		height: 88px;
	}
	.con2-div {
		background: #F1F1F1;
		width: 115px;
		height: 1px;
	}
	.con2-p1 {
		font-size: 15px;
		color: #252B3A;
	}
	.con2-p2 {
		font-size: 12px;
		color: #80838C;
	}
	.content3 {
		width: 100%;
		min-width: 1320px;
		height: 1060px;
		background: #F5F8FC;
	}
	.con3 {
		width: 1320px;
		height: 100%;
		margin: 0 auto;
		padding-top: 95px;
		flex-direction: column;
	}
	.con3 > img {
		width: 779px;
		height: 517px;
		margin-top: 170px;
	}
	.con3-title {
		width: 100%;
		padding: 0 260px;
		font-weight: bold;
		text-align: center;
	}
	.title-1 {
		color: #222;
		font-size: 30px;
	}
	.title-2 {
		color: #666;
		font-size: 15px;
		line-height: 23px;
		margin-top: 48px;
	}
	.content4 {
		width: 1320px;
		padding: 175px 15px 40px;
		margin: 0 auto;
	}
	.con4-title {
		text-align: center;
	}
	.con4-div {
		margin-top: 100px;
		flex-wrap: wrap;
	}
	.con4-div > div:nth-child(4n) {
		margin-right: 0;
	}
	.con4-item {
		flex-direction: column;
		margin-right: 330px;
		margin-bottom: 155px;
	}
	.con4-item > img {
		width: 64px;
		height: 64px;
		margin-bottom: 30px;
	}
	.con4-item > p {
		font-size: 18px;
		color: #222;
		font-weight: bold;
	}
</style>
<template>
	<div>
		<div class="content">
			<div class="top-title">
				<p class="title-text1">APP软件开发能为企业带来什么？</p>
				<p class="title-text2">同样的产品比质量，同样的质量比技术，同样的技术比效率，同样的效率比服务。</p>
			</div>
		</div>
		<div class="content2 flex">
			<div class="con2-item flex-around flex-y-center" v-for="(item, index) in content2List" :key="index">
				<img :class="`con2-img${index+1}`" :src="item.img">
				<p class="con2-p1">{{item.title}}</p>
				<div class="con2-div"></div>
				<p class="con2-p2">{{item.text}}</p>
			</div>
		</div>
		<div class="content3 flex-x-center">
			<div class="con3 flex-y-center">
				<div class="con3-title">
					<p class="title-1">全方位一站式</p>
					<p class="title-2">移动互联网已颠覆我们的时代，商业、社交、视频、新闻、工具等领域，移动应用渗透率已超过80%，在团购、旅游和零售业，移动端收入规模已经超越PC端，手机APP已经成为企业不可或缺的线上阵地</p>
				</div>
				<img src="../../../assets/images/product-img17.png" alt="">
			</div>
		</div>
		<div class="content4">
			<div class="con4-title">
				<p class="title-1">我们的服务</p>
				<p class="title-2">敏捷运筹，决胜千里。提供互联网+管家式服务</p>
			</div>
			<div class="con4-div flex-between">
				<div class="con4-item flex-y-center" v-for="(item, index) in content4List" :key="index">
					<img :src="item.img" alt="">
					<p>{{item.text}}</p>
				</div>
			</div>
		</div>
		<Bottom :minWidth="minWidth"></Bottom>
	</div>
</template>

<script>
	import Bottom from "../../../../components/bottom.vue"
	export default {
		name: "app",
		data() {
			return {
				minWidth: 1320,
				content2List: [
					{img: require("../../../assets/images/product-img12.png"), title: "产品形象", text: "彰显行业潮流"},
					{img: require("../../../assets/images/product-img13.png"), title: "多媒体展示", text: "多媒体展示，丰富的信息和绚丽 的画面，用户体验好"},
					{img: require("../../../assets/images/product-img14.png"), title: "移动电子商务平台", text: "随时随地和客户轻松做生意"},
					{img: require("../../../assets/images/product-img15.png"), title: "口碑传播", text: "通过微博、微信广泛传播"},
					{img: require("../../../assets/images/product-img16.png"), title: "占领移动互联网入口", text: "移动营销"},
				],
				content4List: [
					{text: "合同签订", img: require("../../../assets/images/product-img18.png")},
					{text: "资讯", img: require("../../../assets/images/product-img19.png")},
					{text: "原型设计", img: require("../../../assets/images/product-img20.png")},
					{text: "客户体验", img: require("../../../assets/images/product-img21.png")},
					{text: "视觉设计", img: require("../../../assets/images/product-img22.png")},
					{text: "产品研发", img: require("../../../assets/images/product-img23.png")},
					{text: "软件测试", img: require("../../../assets/images/product-img24.png")},
					{text: "上线运营", img: require("../../../assets/images/product-img25.png")},
				]
			}
		},
		components: {
			Bottom
		}
	}
</script>
